import type { JSX } from "solid-js";
import classes from "~/solidJs/entities/SolidModalView/ui/LayoutSolidModalView.module.scss";
import CrossIcon from "~/Icons/crossIcon.svg?component-solid"
import { Button } from "~/solidJs/shared";
import { twJoin } from "tailwind-merge";
type CloseBtnProps = {
	onClick: JSX.CustomEventHandlersCamelCase<HTMLButtonElement>["onClick"];
	class?: string;
};
export const CloseBtn = (props: CloseBtnProps) => (
  <Button onclick={props.onClick} class={twJoin("absolute right-4 top-4 p-1",props.class)} >
    <CrossIcon class="size-6 text-white"/>
  </Button>
);
