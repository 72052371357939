import { createSignal } from "solid-js";
import { sessionCounter } from "../helpers";
import type { DiscountOfferModalVariants } from "~/solidJs/widgets/DiscountOfferModal/DiscountOfferModal";
import type { NodeInstruction } from "~/solidJs/widgets/NodeInstructionWidget/NodeInstructionWidget";
import type { PremiumNnsModalStore } from "~/solidJs/widgets/PremiumNnsModal/PremiumNnsModal";
import type { SaveNewProjectModalStore } from "~/solidJs/widgets/SaveNewProjectModal/SaveNewProjectModal";
import type { ManageSubsccriptionStore } from "~/solidJs/widgets/ManageSubscriptionModal/ManageSubscriptionModal";
import { createHistoryStore } from "~/solidJs/entities/HistoryListEntity/models/historyContext";

export const [getIsShareModalVisible, setIsShareModalVisible] = createSignal(false);
export const [getIsHistoryVisible, setIsHistoryVisible] = createSignal(
	sessionCounter.getCurSessionCount() === 5
);
export const historyStore = createHistoryStore();
export const [getIsFeedVisible, setIsFeedVisible] = createSignal(false);
export const [getIsMissingWorkspaceByLinkVisible, setIsMissingWorkspaceByLinkVisible] =
	createSignal(false);

export const [getIsManageSubscriptionModalVisible, setIsManageSubscriptionModalVisible] =
	createSignal<ManageSubsccriptionStore>({
		isVisible: false,
		unsubscribe: false,
		btnText: "Manage Subscription"
	});
export const [getIsBlackFridayVisible, setIsBlackFridayVisible] = createSignal(false);
export const [getIsHalloWeenVisible, setIsHalloWeenVisible] = createSignal(false);
export const [getIsHalloWeenHeaderVisible, setIsHalloWeenHeaderVisible] = createSignal(false);
export const [getIsNewYearVisible, setIsNewYearVisible] = createSignal(false);
export const [getIsBetaVisible, setIsBetaVisible] = createSignal(false);
export const [getIsGift, setIsGift] = createSignal(false);
export const [getIsPremiumNnsModalVisible, setIsPremiumNnsModalVisible] =
	createSignal<PremiumNnsModalStore>(false);
export const [getIsSaveNewProjectVisible, setIsSaveNewProjectVisible] =
	createSignal<SaveNewProjectModalStore>({ isVisible: false });
export const [getIsDiscountOfferModalVisible, setIsDiscountOfferModalVisible] =
	createSignal<DiscountOfferModalVariants>({
		isVisible: false,
		headerText: "Special limited 24h offer! 10% off any plan",
		promoCode: "POWER"
	});
export const [getIsTutorialPageVisible, setIsTutorialPageVisible] = createSignal(false);

export const [getNodeInstruction, setNodeInstruction] = createSignal<NodeInstruction>({
	isVisible: false
});
export const [getIsQuickTourVisible, setIsQuickTourVisible] = createSignal(false);
export const [getIsOnboardingVisible, setIsOnboardingVisible] = createSignal(false);
export const [getIsClearWorkspaceVisible, setIsClearWorkspaceVisible] = createSignal(false);
export const [getIsAproveLeaveVisible, setIsAproveLeaveVisible] = createSignal(false);
