import { axiosClient } from "~/js/network/axiosClient";
import type { IUser, UserOnlineInformation } from "~/solidJs/shared/helpers/types";
import { BaseApi } from "./baseApi";

export class UserApi extends BaseApi {
	static baseUrl = BaseApi.baseUrl + "users/";
	static supertokensApi = "auth/";

	static async getUserInitInfo() {
		try {
			const resp = await axiosClient.axiosClient.get<IUser>("/api/v2/front/init");
			return resp.data;
		} catch (e) {}
	}

	static async getUserOnlineInfo() {
		try {
			const resp = await axiosClient.axiosClient.get<UserOnlineInformation>("/api/v2/front/online");
			return resp.data;
		} catch (e) {}
	}

	static async getOAuthLink(providerId: string, callbackUrl: string) {
		const { data } = await axiosClient.axiosClient.get<{
			status: string;
			url: string;
		}>(this.supertokensApi + "authorisationurl", {
			params: {
				thirdPartyId: providerId,
				redirectURIOnProviderDashboard: callbackUrl
			}
		});
		return data;
	}
}
