import { Dynamic } from "solid-js/web";
import { type ComponentProps, type JSX, splitProps } from "solid-js";
import type { ElementType } from "~/solidJs/shared/helpers/types/IElemntTypeEnum";
import { tv, type VariantProps } from "tailwind-variants";

export type btnVariants = VariantProps<typeof styles>["color"];
export type btnSizes = VariantProps<typeof styles>["size"];
type ButtonCustomProps<E extends ElementType = ElementType> = {
	defaultIconStyles?: boolean;
	children?: JSX.Element;
	variant?: btnVariants;
	pending?: boolean;
	disabled?: boolean;
	size?: btnSizes;
	className?: string;
	class?: string;
	as?: E;
};
const styles = tv({
	base: "inline-flex px-4 pointer transition-colors relative rounded-lg justify-center font-normal text-sm items-center gap-2 border border-transparent",
	defaultVariants: {
		size: "small",
		color: "basic"
	},
	variants: {
		size: {
			small: "py-2",
			medium: "py-4 px-3",
			large: "py-3 sm:p-4"
		},
		color: {
			actionviolent:
				"focus:ring-2 text-white hover:bg-[#9B71EF] focus:ring-offset-2 transition-all text-white bg-interaction-violent hover:bg-purple-500 focus:ring-gray-500",
			basic: "focus:ring-2 focus:ring-offset-2 transition-all text-gray-500 hover:bg-gray-400 focus:ring-gray-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:ring-offset-gray-800",
			premium:
				"duration-500 hover:-pos-200 focus:outline-none focus:ring-blue-500 focus:ring-2 focus:ring-offset-2 transition-all bg-gradient-to-r from-violet-500 via-fuchsia-400 to-violet-500 bg-size-200 text-white hover:bg-gray-100 focus:ring-gray-500",
			hoverMenu:
				"text-text-primary bg-background-gray hover:bg-[#E8EAEE] focus:ring-gray-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:ring-offset-gray-800 flex-col [&>svg]:h-4 focus:ring-2 focus:ring-offset-2 transition-all",
			premiumOutline:
				"rounded-full bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-transparent",
			outline:
				"border-1 border-background-black text-text0primary hover:bg-gray-200",
			dark: "text-white bg-[#2D2D2D]",
			opacity: "bg-transparent text-white opacity-60 hover:opacity-100",
			iconContainer:
				"[&_path]:stroke-[rgba(166, 166, 166, 1)] hover:[&_path]:stroke-[rgba(41, 45, 50, 1)] hover:bg-[#F1F1F1]"
		},
		disabled: {
			true: "pointer-events-none opacity-50"
		},
		pending: {
			true: "pointer-events-none text-[#adadad] size-1000 bg-gradient-to-r from-[#3b3b3b] via-[#6e6e6e] to-[#3b3b3b] animate-shimmer"
		}
	}
});
export type ButtonProps<E extends ElementType> = ButtonCustomProps<E> &
	Omit<ComponentProps<E>, keyof ButtonCustomProps<E>>;

const defaultElement = "button";

export const Button = <E extends ElementType = typeof defaultElement>(props: ButtonProps<E>) => {
	const [notNativeAttributes, others] = splitProps(props, [
		"className",
		"class",
		'class',
		'disabled',
		"as",
		"defaultIconStyles",
		"children",
		"variant",
		"pending",
		"size"
	]);

	const Element = notNativeAttributes.as || defaultElement;

	return (
		<Dynamic
			component={Element}
			class={styles({
				color: props.variant,
				size: props.size,
				pending: notNativeAttributes.pending,
				disabled: props.disabled,
				class: props.class || props.className
			})}
			{...others}
		>
			{notNativeAttributes.children}
		</Dynamic>
	);
};
