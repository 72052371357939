import type { premiumCheckoutType } from "~/solidJs/entities/PremiumPopUp/models/premiumPopUpContext";
import { BaseApi } from "./baseApi";
import { axiosClient } from "~/js/network/axiosClient";
type Payment = {
	id: number;
	user_id: number;
	customer_id: number;
	price: number;
	discount_code: string;
	transaction_id: string;
	subscription_name: string;
};
export class PaymentApi extends BaseApi {
	static baseUrl = "api/payment";
	static getPaymentsUrl = "/api/users/payments_log";
	static async createCheckoutSession(props: {
		isTrial?: boolean;
		nameId: string;
		activeInterval: string;
		type?: premiumCheckoutType;
		promoCode?: string;
		clientId?: number;
	}) {
		const { data } = await axiosClient.axiosClient.post<{ url: string }>(
			"api/v2/payments/create-checkout-session",
			{
				subscription_name: props.nameId,
				length: props.activeInterval,
				...(props.type ? { kind: props.type } : {}),
				...(props.promoCode ? { promo_code: props.promoCode } : {})
			}
		);
		return data;
	}
	
	static async createPortalSession() {
		const { data } = await axiosClient.axiosClient.post<{ url: string }>(
			"/api/v2/payments/create-portal-session"
		);
		return data;
	}
	
	static async cancelYoukassaSubscription() {
		const { data } = await axiosClient.axiosClient.post<{ url: string }>("/api/v2/front/modify_recurrent", {
			enable_recurrent_payments: false
		});
		return data;
	}
	
	static async logTryUnsubscribe() {
		const { data } = await axiosClient.axiosClient.post<{ url: string }>("/api/v2/front/try_unsubscribe");
		return data;
	}
	
	static async getUserPayments() {
		const { data } = await axiosClient.axiosClient.get<Payment[]>(this.getPaymentsUrl);
		return data;
	}
}
