import type { RouteDataFunc } from "@solidjs/router";
import { getUserStore } from "~/solidJs/entities/HeaderMain/models";
import { getUserAndSetStore, isUserInited } from "~/solidJs/shared/helpers/userHelpers";

export const getUser = async () => {
	const user = getUserStore();
	if (isUserInited(user)) {
		return user;
	}
	return await getUserAndSetStore();
};

export const IndexLayoutData: RouteDataFunc = (props) => {
	return { user: getUser() };
};
