import { type Component, type ComponentProps, createContext, createSignal } from "solid-js";
import { type SetStoreFunction, createStore } from "solid-js/store";
import Img2ImgIcon from "~/Icons/img2img.svg?component-solid";
import Text2img from "~/Icons/text2img.svg?component-solid";
import InpaintIcon from "~/Icons/inpaint.svg?component-solid";
import Outpaint from "~/Icons/outpaint.svg?component-solid";
import type { IDataTypeTypes } from "~/solidJs/shared/helpers/types/INodeDefinition";
import type { CustomFabricRect } from "~/solidJs/shared/helpers/types/IFabricJsHelpersTypes";

export type FabricDrawRectangle = {
	typeId: "RECTANGLE";
};
export type FabricDrawSelect = {
	typeId: "SELECT";
};
export type FabricDrawImage = {
	typeId: "IMAGE";
	imageSrc: string;
};
export type FabricDrawDraw = {
	typeId: "DRAW";
};
export type FabricDrawDrawMask = {
	typeId: "DRAW_MASK";
};

export type FabricDrawTypeUnion =
	| FabricDrawRectangle
	| FabricDrawSelect
	| FabricDrawDraw
	| FabricDrawImage
	| FabricDrawDrawMask;

export type FabricColorsUnion = string;
export type MagicCanvasRenderMode = "txt2img" | "img2img" | "inpaint" | "outpaint";
export const magicCanvasRenderMode: Array<{ value: MagicCanvasRenderMode; label: string }> = [
	{ value: "txt2img", label: "Text2image" },
	{ value: "img2img", label: "Image2image" },
	{ value: "inpaint", label: "Inpainting" },
	{ value: "outpaint", label: "Outpainting" }
];
export const renderModeToModifyParams: {
	[key in MagicCanvasRenderMode]: { name: string; value: IDataTypeTypes; isHidden?: boolean }[];
} = {
	txt2img: [
		{
			name: "negative_prompt",
			value: "_____",
			isHidden: true
		}
	],
	img2img: [
		{
			name: "negative_prompt",
			value: "_____",
			isHidden: true
		}
	],
	outpaint: [
		{
			name: "negative_prompt",
			value: "_____, tiled, straight line, collage, separated, frame, framed, text, letters, tape, rectangles",
			isHidden: true
		}
	],
	inpaint: [
		{
			name: "negative_prompt",
			value: "_____, tiled, straight line, collage, separated, frame, framed, text, letters, tape, rectangles",
			isHidden: true
		}
	]
};
export const MagicCanvasRenderToIcon: {
	[key in MagicCanvasRenderMode]: Component<ComponentProps<"svg">>;
} = {
	img2img: Img2ImgIcon,
	txt2img: Text2img,
	inpaint: InpaintIcon,
	outpaint: Outpaint
};
export const magicCanvasRenderModeToModel: {
	[key in MagicCanvasRenderMode]: { label: string; value: string }[];
} = {
	img2img: [
		{ value: "Phygital Creator/phygc-rnd-stable-diffusion", label: "SD" },
		{ value: "Phygital Creator/phygc-rnd-stable-xl-img2img", label: "SD XL" }
	],
	txt2img: [
		{ value: "Phygital Creator/phygc-rnd-stable-diffusion", label: "SD" },
		{ value: "Phygital Creator/phygc-rnd-stable-xl", label: "SD XL" }
	],
	inpaint: [{ value: "Phygital Creator/phygc-rnd-stable-diffusion-inpainting", label: "SD" }],
	outpaint: [{ value: "Phygital Creator/phygc-rnd-stable-diffusion-inpainting", label: "SD" }]
};
export type completedTaskInfo = {
	left: number;
	top: number;
	width: number;
	height: number;
	urls: string[];
	ids: number[];
};
type errorTaskInfo = {
	message: string;
} & Pick<completedTaskInfo, "left" | "top">;

export type IMagicCanvasProxyStore = {
	renderMode: MagicCanvasRenderMode;
	isRenderRegionLocked: boolean;
	taskStatusText?: string;
	brushWidth: number;

	fillColor: FabricColorsUnion;

	drawType: FabricDrawTypeUnion;
	selecteModelIndex: number;
	zoom: number;
	xPos: number;
	yPos: number;

	completedTaskInfo?: completedTaskInfo;
	errorTaskInfo?: errorTaskInfo;
};

export type IMagicCanvasProxyStoreSetter = SetStoreFunction<IMagicCanvasProxyStore>;
export type IMagicCanvasStore =
	| {
			fabricCanvas: undefined;
	  }
	| {
			fabricCanvas: fabric.Canvas;
	  };
			
export type IMagicCanvasRenderRegionStore =
	| {
			renderRegion: undefined;
	  }
	| {
			renderRegion: CustomFabricRect;
	  };

export const createMagicCanvasStore = () => {
	const [getMagicCanvasStore, setMagicCanvasStore] = createSignal<IMagicCanvasStore>({
		fabricCanvas: undefined,
	});

	const [getMagicCanvasProxyStore, setMagicCanvasProxyStore] =
		createStore<IMagicCanvasProxyStore>({
			fillColor: "#9570FC",
			isRenderRegionLocked: false,
			brushWidth: 10,
			renderMode: "txt2img",
			selecteModelIndex: 0,
			drawType: {
				typeId: "SELECT"
			},
			xPos: 0,
			yPos: 0,
			zoom: 1
		});

	return {
		getMagicCanvasStore,
		setMagicCanvasStore,
		getMagicCanvasProxyStore,
		setMagicCanvasProxyStore,
	} as const;
};
export const createMagicCanvasRenderStore = () => {

	const [getMagicCanvasRenderRegionStore, setMagicCanvasRenderRegionStore] = createSignal<IMagicCanvasRenderRegionStore>({
		renderRegion: undefined
	});


	return {
		getMagicCanvasRenderRegionStore,
		setMagicCanvasRenderRegionStore
	} as const;
};
export type MagicCanvasContextType = ReturnType<typeof createMagicCanvasStore>
export const MagicCanvasContext = createContext<MagicCanvasContextType>();
export type MagicCanvasRenderContextType = ReturnType<typeof createMagicCanvasRenderStore>
export const MagicCanvasRenderContext = createContext<MagicCanvasRenderContextType>();
