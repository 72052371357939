type SubscriptionStatus = "active" | "due" | "inactive";

export type IUserSubscriptionPlans =
	| "freemium"
	| "trialing"
	| "superuser"
	| "advanced"
	| "enterprise"
	| "plus"
	| "premium"
	| "plus_max"
	| "api_starter"
	| "api_startup"
	| "api_enterprise";

export enum UserSubscriptionPlanEnum {
	Freemium = "freemium",
	Trialing = "trialing",
	Superuser = "superuser",
	Advanced = "advanced",
	Premium = "premium",
	Plus = "plus",
	PlusMax = "plus_max",
	Enterprise = "enterprise",
	ApiStarter = "api_starter",
	ApiStartup = "api_startup",
	ApiEnterprise = "api_enterprise"
}

/**User information */
export type IUser = {
	id: string;
	status: string;
	email: string;
	group: UserSubscriptionPlanEnum;
	start_time: string | null;
	end_time: string | null;
	subscription: "yookassa" | "stripe" | null;
	external_auth?: boolean;
	"st-ev": any;
	/**
	 * @description name of tariff
	 */
	name: string;
	/**
	 * is limits aplyed to user
	 */
	is_limited: boolean;
	/**
	 * @description number of monthly runs limit
	 */
	total_runs: number;
	/**
	 * @description is user in trial tariff
	 */
	is_trial: boolean;

	workspace_limit: number;
	/**
	 * @description number of avalibale monthly runs
	 */
	available_runs: number;
	/**
	 * @description date when limit on runs will be updated
	 */
	become_available_at: string | null;
	/**
	 * @description date when user was banned
	 */
	banned_at?: string;
	/**
	 * @description record of next paymnts key is the future date
	 */
	next_payment?: Record<string, number>;
	subscription_status?: SubscriptionStatus;
	/**
	 * @description can user run is_disabled: true nodes
	 */
	can_run_disabled: boolean;
	/**
	 * @description duplicate of subscription field, used to determine method for users with
	 */
	payment_method: "yookassa" | "stripe" | null;

	/**
	 * false - new user has not completed questionnaire, true - new user completed questionnaire, null - old user
	 */
	questionnaire: boolean | null;
	/**
	 * is prompt helper avalible to user
	 */
	prompt_helper_assistant_available: boolean;
	/**
	 * is prompt assistant avalible to user
	 */
	chat_gpt_assistant_available: boolean;
};

export type UserOnlineInformation = Pick<
	IUser,
	"group" | "available_runs" | "become_available_at" | "banned_at"
>;
