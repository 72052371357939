import { createContext, createSignal } from "solid-js";

export type collapsableContextType = {
	isCollapsed: boolean;
};
export const createCollapsableStore = (defaultCollapsed?: boolean) => {
	const [getCollapsableStore, setCollapsableStore] = createSignal<collapsableContextType>({
		isCollapsed: defaultCollapsed !== undefined ? defaultCollapsed : true
	});
	return {
		getCollapsableStore,
		setCollapsableStore
	};
};
export const CollapsableContext = createContext<{} & ReturnType<typeof createCollapsableStore>>();
