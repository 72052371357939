import { type IUserStore, getUserStore, setUserStore } from "~/solidJs/entities/HeaderMain/models";
import { IUser, UserSubscriptionPlanEnum } from "./types";
import { UserApi } from "../api/user";
import { getCookie } from "./cookiesHelpers";
import { PaymentApi } from "../api/PaymentApi";
import EventAnalytics, { GrayLog } from "~/js/utilities/EventAnalytics";
import { getPrivateMessageFromError } from "./errorHelpers";
import { config } from "~/js/network/network-handler";

export const getIsAccesDisabled = (...disabledGroups: UserSubscriptionPlanEnum[]) => {
	const group = getUserStore()?.group;

	if (!group) return true;
	for (const disabledGroup of disabledGroups) {
		if (group === disabledGroup) return true;
	}
	return false;
};
export function isEmailVerified(tokenPayload?: Record<string, any> | null) {
	return tokenPayload?.["st-ev"]?.v;
}

export async function getUserInfo(): Promise<IUser | null> {
	const refreshToken = getCookie("st-refresh-token");
	if (refreshToken === null) {
		return null;
	}
	const session = await UserApi.getUserInitInfo();
	if (!session) return null;

	return session;
}

export async function getUserAndSetStore() {
	const user = await getUserInfo();
	setUserStore(user || undefined);
	return user;
}

export async function revalidateUserOnlineInfo() {
	const userOnline = await UserApi.getUserOnlineInfo();
	if (!userOnline) return;
	setUserStore((prev) => {
		if (!prev) return userOnline;
		return { ...prev, ...userOnline };
	});
	return userOnline;
}

export function isUserInited(user?: IUser){
  return user && !!user.id
}

export function createRevalidateUserOnlineInfoInterval() {
	let interval: NodeJS.Timeout;
	let lastRevalidation = 0;
	
	const onRevalidateOnlineInfo = async () => {
		if (Date.now() - lastRevalidation < 10000) {
			return;
		}
		lastRevalidation = Date.now();
		await revalidateUserOnlineInfo();
	};
	
	const start = () => {
		interval = setInterval(onRevalidateOnlineInfo, 10000);
		window.addEventListener("focus", onRevalidateOnlineInfo);
	};

	const stop = () => {
		clearInterval(interval);
		window.removeEventListener("focus", onRevalidateOnlineInfo);
	};

	return { start, stop };
}

export function isAuthenticated(user?: IUserStore | null): boolean {
	return !!user;
}

export function isUserNeedToSetupAccount(user?: IUserStore | null): boolean {
	return (
		localStorage.getItem("setupAccountDebug") === "true" ||
		(!!user && (user.questionnaire === null || user.questionnaire === false))
	);
}

export function isUserHasAccess(
	user: IUserStore | undefined | null,
	accessMap: Record<string, boolean>
): boolean {
	if (!user) {
		return false;
	}
	return user.group in accessMap;
}
/**
 * @description gets payments from the server and send info to analitycs system
 */
export async function registratePaymentsInAnalitycs() {
	if (config?.IS_PRODUCTION !== "true") {
		return;
	}
	let payments: Awaited<ReturnType<typeof PaymentApi.getUserPayments>> | undefined;
	try {
		payments = await PaymentApi.getUserPayments();
	} catch (e) {
		console.error(e);
		EventAnalytics.sendErrorInfo("get-payments-error", {
			message: getPrivateMessageFromError(e)
		});
		return;
	}
	if (!payments?.length) {
		return;
	}
	try {
		for (const payment of payments) {
			try {
				GrayLog.debug({
					log_name: "purchase",
					message: "send purchase event",
					isNeedToSendToAnalitics: false,
					price: payment.price,
					transaction_id: payment.transaction_id,
					code: payment.discount_code
				});
			} catch (e) {}
			EventAnalytics.purchace(payment.price, payment.transaction_id, payment.discount_code);
		}
	} catch (e) {
		console.error(e);
		EventAnalytics.sendErrorInfo("send-payments-error", {
			message: getPrivateMessageFromError(e)
		});
	}
}

export const isUserFree = (user?: IUser | null) => {
	return (
		user?.group === UserSubscriptionPlanEnum.Freemium ||
		user?.group === UserSubscriptionPlanEnum.Trialing
	);
};
