import type { fabric } from "fabric";
import { createSignal, createContext } from "solid-js";
import { createCollapsableStore } from "~/solidJs/entities/CollapsableFullScreenEntity/models/collapsableContext";
import { createMagicCanvasStore } from "~/solidJs/features/MagicCanvasFeature/MagicCanvasFeatureContext";
import type { IFileType } from "~/solidJs/shared";
import type { EntitySize, Coordinates } from "~/solidJs/shared/helpers/types/ISizeble";

export enum ComposeSizeModeEnum {
	Auto = "auto",
	_1024_1024  = "1024_1024"
}

export type SizeMode = {
	id: ComposeSizeModeEnum;
	label: string;
	size?: EntitySize;
	position?: Coordinates;
};

export type ExportRegionState = {
} & SizeMode;

export type SaveEvent = {
	state: ReturnType<typeof fabric.Canvas.toJSON>;
	file: IFileType | null;
	exportRegionState: ExportRegionState;
};

export const SizeModes: SizeMode[] = [
	{
		id: ComposeSizeModeEnum.Auto,
		label: "Fit to content"
	},
	{
		id: ComposeSizeModeEnum._1024_1024 ,
		label: "1:1",
		size: {
			height: 1024,
			width: 1024
		}
	}
];

export type ComposeStore = {
	isVisible: boolean;
	nodeUUID?: string;
};

const createComposeStore = () => {
	const [getSizeStore, setSizeStore] = createSignal<SizeMode>(SizeModes[0]);
	const [getComposeStore, setComposeStore] = createSignal<ComposeStore>({ isVisible: false });
	return { getComposeStore, setComposeStore, getSizeStore, setSizeStore };
};
export const magicCanvasComposeStore = createMagicCanvasStore();
export const composeStore = createComposeStore();

export type ComposeContextType = typeof composeStore;

export const ComposeContext = createContext<ComposeContextType>();

export const composeCollapseStore = createCollapsableStore();
